const BabylonjsLogo = (props: any) => (
  <svg
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1280 436'
    {...props}
  >
    <defs>
      <style>
        {
          '.cls-1{fill:#bb464b;}.cls-2{fill:#fff;}.cls-3{fill:#e0684b;}.cls-4{fill:#e0ded8;}.cls-5{fill:#d5d2ca;}'
        }
      </style>
    </defs>
    <title>{'babylonjs_identity_color'}</title>
    <path
      className='cls-1'
      d='M390.34,153.16h21.74V201.9c3.86-6.49,11.57-12.8,26.82-12.8,23.84,0,36.82,19.64,36.82,45.41,0,26.47-13.5,48.91-39.27,48.91-13.68,0-20.16-4.56-24.55-11.75a67.68,67.68,0,0,1-.87,9.65H390.17c.17-9.82.17-19.81.17-29.81Zm42.25,53.65c-15.78,0-21.56,10.52-21.56,29.8,0,16.48,5.08,29.28,21.21,29.28,13.67,0,21.21-12.27,21.21-30.86C453.45,218.55,446.79,206.81,432.59,206.81Z'
    />
    <path
      className='cls-1'
      d='M564.42,259.23c0,8.41.7,19.81,1.23,22.09H544.79c-.7-1.93-1.23-6.49-1.41-9.82-3.15,5.43-9.64,11.92-26.12,11.92-21.56,0-30.68-14.2-30.68-28.05,0-20.34,16.31-29.8,42.78-29.8H543v-6.14c0-7-2.28-14.37-15.77-14.37-11.93,0-14.38,5.43-15.61,12.09H490.79c1.23-14.72,10.52-28.22,37.34-28.05,23.49.18,36.29,9.47,36.29,30.51Zm-21.21-20H531.46c-16,0-23,4.73-23,14.9,0,7.37,4.91,13.33,14.55,13.33,17.88,0,20.16-12.28,20.16-25.6Z'
    />
    <path
      className='cls-1'
      d='M584.05,153.16h21.74V201.9c3.86-6.49,11.57-12.8,26.82-12.8,23.85,0,36.82,19.64,36.82,45.41,0,26.47-13.5,48.91-39.27,48.91-13.67,0-20.16-4.56-24.54-11.75a67.51,67.51,0,0,1-.88,9.65H583.88c.17-9.82.17-19.81.17-29.81Zm42.25,53.65c-15.77,0-21.56,10.52-21.56,29.8,0,16.48,5.08,29.28,21.21,29.28,13.68,0,21.22-12.27,21.22-30.86C647.17,218.55,640.5,206.81,626.3,206.81Z'
    />
    <path
      className='cls-1'
      d='M696.78,191.21c11.22,35.23,19.28,61.53,21,70.3H718c2.28-9.47,7.89-29.81,20-70.3h21.74l-31,93.26C719.92,310.59,712.9,318,692.39,318a94,94,0,0,1-11-.7V299.37c1.93.18,4.56.35,6.84.35,9.81,0,14.37-3.68,18.4-15.42l-33.13-93.09Z'
    />
    <path className='cls-1' d='M769.35,281.32V153.16h21.74V281.32Z' />
    <path
      className='cls-1'
      d='M894,236.09c0,26.82-15.78,47.33-44,47.33-27.34,0-43.3-20-43.3-47,0-27.35,16.31-47.34,44.36-47.34C877.17,189.1,894,207.86,894,236.09Zm-65.22.17c0,18.23,8.24,29.8,21.74,29.8,13.67,0,21.21-11.39,21.21-29.62,0-19.46-7.54-30-21.56-30C836.85,206.46,828.78,216.63,828.78,236.26Z'
    />
    <path
      className='cls-1'
      d='M909.77,213.82c0-7.71,0-15.78-.17-22.61h21a96.4,96.4,0,0,1,.88,12.62c3.16-6.31,10.52-14.73,26.82-14.73,17.71,0,30,11.57,30,34.71v57.51H966.57v-55c0-11.22-4-19.29-16-19.29-12.8,0-19.11,8.24-19.11,24.9v49.44H909.77Z'
    />
    <path className='cls-1' d='M1009,281.32V252.21h23.31v29.11Z' />
    <path
      className='cls-1'
      d='M1068.25,193.14v92.91c0,24-5.79,32.08-23,32.08-.7,0-4.2-.18-5.43-.35V307.26a26.46,26.46,0,0,0,3.68.18c11.75,0,13-6.31,13-19.64V193.14Zm-11.75-22.8V152.29h11.75v18.05Z'
    />
    <path
      className='cls-1'
      d='M1094.89,256.6c2.81,10.52,10.52,17,23.5,17,14.37,0,20.16-6.48,20.16-15.6,0-9.29-4-13.67-22.44-18.06-24-5.78-29.46-13.15-29.46-24.89,0-12.27,9.3-24,30.33-24,21.22,0,30.68,12.62,32.08,25.07h-11.57c-1.4-6.66-6.66-15.25-20.86-15.25-14,0-18.05,6.84-18.05,13.32,0,7.37,3.85,11.05,20.68,15.08,25.77,6.13,31.56,14.72,31.56,27.87,0,15.78-12.62,26.3-32.78,26.3-21.39,0-32.61-11.05-34.89-26.82Z'
    />
    <path className='cls-2' d='M238.24,89.38l-110.85,64v128l110.85,64,110.85-64v-128Z' />
    <polygon
      className='cls-3'
      points='349.09 153.38 314.39 133.35 281.02 152.62 315.71 172.65 349.09 153.38'
    />
    <polygon
      className='cls-3'
      points='160.77 172.65 271.62 108.65 238.24 89.38 127.39 153.38 160.77 172.65'
    />
    <polygon
      className='cls-3'
      points='203.55 237.41 238.24 257.44 272.94 237.41 238.24 217.38 203.55 237.41'
    />
    <polygon
      className='cls-1'
      points='315.71 172.65 315.71 262.1 238.24 306.83 160.77 262.1 160.77 172.65 127.39 153.38 127.39 281.37 238.24 345.37 349.09 281.37 349.09 153.38 315.71 172.65'
    />
    <polygon
      className='cls-1'
      points='238.24 177.31 203.55 197.34 203.55 237.41 238.24 217.38 272.94 237.41 272.94 197.34 238.24 177.31'
    />
    <polygon
      className='cls-4'
      points='315.71 172.65 272.94 197.34 272.94 237.41 238.24 257.44 238.24 306.83 315.71 262.1 315.71 172.65'
    />
    <polygon
      className='cls-5'
      points='160.77 172.65 203.55 197.34 203.55 237.41 238.24 257.44 238.24 306.83 160.77 262.1 160.77 172.65'
    />
    <polygon
      className='cls-2'
      points='271.62 108.65 314.39 133.35 281.02 152.62 315.71 172.65 272.94 197.34 238.24 177.31 203.55 197.34 160.77 172.65 271.62 108.65'
    />
  </svg>
)

export default BabylonjsLogo
