/* eslint-disable import/no-anonymous-default-export */
import { FreeCamera, Vector3, HemisphericLight, MeshBuilder } from '@babylonjs/core'
import SceneComponent from 'babylonjs-hook' // if you install 'babylonjs-hook' NPM.
import BabylonjsLogo from './BabylonjsLogo'
import './App.css'
import { TreeMenu } from './SideBar/TreeMenu'
import { onRender } from '../Contents/common/onRender'

let box: any

const onSceneReady = (scene: any) => {
  // This creates and positions a free camera (non-mesh)
  var camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene)

  // This targets the camera to scene origin
  camera.setTarget(Vector3.Zero())

  const canvas = scene.getEngine().getRenderingCanvas()

  // This attaches the camera to the canvas
  camera.attachControl(canvas, true)

  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  var light = new HemisphericLight('light', new Vector3(0, 1, 0), scene)

  // Default intensity is 1. Let's dim the light a small amount
  light.intensity = 0.7

  // Our built-in 'box' shape.
  box = MeshBuilder.CreateBox('box', { size: 2 }, scene)

  // Move the box upward 1/2 its height
  box.position.y = 1

  // Our built-in 'ground' shape.
  MeshBuilder.CreateGround('ground', { width: 6, height: 6 }, scene)
}

export default () => (
  <div className='grid grid-flow-col'>
    <TreeMenu />

    <div className='grid grid-rows-[100px_1fr] items-center bg-BackGround p-2'>
      <div className='h-28 grid grid-flow-col place-content-center'>
        <BabylonjsLogo height='100px' />
        <div className='text-[#bb464b] text-3xl font-bold self-center'>튜토리얼 공부</div>
      </div>
      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender(box)}
        id='my-canvas'
      />
    </div>
  </div>
)
