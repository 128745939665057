import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { useState } from 'react'

export const SingleMenu = ({ name, childrenName }: any) => {
  const [open, setOpen] = useState(true)
  // const navigate = useNavigate()

  const toggle = () => {
    setOpen((open) => !open)
  }

  return (
    <>
      <div className='grid grid-flow-col place-content-start w-64'>
        {open ? (
          <ChevronDownIcon className='w-6 h-6' onClick={toggle} />
        ) : (
          <ChevronRightIcon className='w-6 h-6' onClick={toggle} />
        )}
        <div className='w-64 font-bold'>{name}</div>
      </div>
      {childrenName.map(
        (childName: string) =>
          open && (
            <div key={childName} className='pl-10 w-64'>
              {childName}
            </div>
          )
      )}
    </>
  )
}
