/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */
export const onRender = (box: any) => (scene: any) => {
  if (box !== undefined) {
    var deltaTimeInMillis = scene.getEngine().getDeltaTime()

    const rpm = 10
    box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000)
  }
}
