import { SingleMenu } from './SingleMenu'

export const TreeMenu = () => {
  return (
    <>
      <div className='w-64 h-screen p-5 bg-BackGround text-SidebarFontColor border-SidebarFontColor border-r'>
        <SingleMenu name={'Your Journey Starts Here'} childrenName={['index']} />
        <SingleMenu
          name={'Getting Started'}
          childrenName={[
            'Firsts',
            'Build A Village',
            'Village Animation',
            'Avoiding Collisions',
            'A Better Environment',
            'Build a Particle Fountain',
            'Light the Night',
            'Ways to See The World',
          ]}
        />
        {/* <SingleMenu name={'카테고리'} childrenName={['카테고리', '매핑관리']} />
        <SingleMenu name={'통계정보'} childrenName={['통계정보']} /> */}
      </div>
    </>
  )
}
